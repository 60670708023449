import { Component } from 'nuxt-property-decorator'
import { TaskStatus } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams } from '~/types/store/store-base-type'

@Component({
  name: 'TaskStatusesMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    // Запуск лоадера перехода по страницам
    this.$wait.start('leaveRouter')
    this.$tasks.statuses.resetItem()
    next()
  }

  getTaskStatuses (pageParams?: PageParams<TaskStatus>, save = true) {
    return this.asyncRequestHandler({
      loader: 'getTaskStatuses'
    },
    this.$tasks.statuses.getList(pageParams,
      save)

    )
  }

  getTaskStatus (id: TaskStatus['id'], save = true) {
    return this.asyncRequestHandler(
      { loader: 'getTaskStatus' },
      this.$tasks.statuses.getItem(id,
        save)

    )
  }

  createTaskStatus () {
    return this.asyncRequestHandler(
      {
        loader: 'createTaskStatus',
        message: (status: TaskStatus) => `Статус ${status.name} создан`
      },
      this.$tasks.statuses.createItem()
    )
  }

  editTaskStatus (id: TaskStatus['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'editTaskStatus',
        message: (status: TaskStatus) => `Статус ${status.name} изменен`
      },
      this.$tasks.statuses.editItem(id)

    )
  }

  removeTaskStatus (id: TaskStatus['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'removeTaskStatus',
        message: (status: TaskStatus) => `Статус ${status.name} удален`
      },
      this.$tasks.statuses.removeItem(id)

    )
  }
}
