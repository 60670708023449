import { Component } from 'nuxt-property-decorator'
import { ConditionsPageParams } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import validatorsPattern from '~/utils/validators'
import { ValidatorParams } from '~/types/store/store-base-type'
import { NCalculator } from '~/types/store/calculator-micro'

@Component({
  name: 'CalculatorConditionsMixin'
})
export default class CalculatorConditionsMixin extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.statesReset('$calculatorConditions')
    next()
  }

  created () {
    this.$wait.end('leaveRouter')
  }

  async getCalculatorConditionsList (pageParams: ConditionsPageParams<NCalculator.ICondition>, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getCalculatorConditionsList' },
      this.$calculatorConditions.getList(pageParams,
        save)

    )
  }

  async getCalculatorConditionsItem (identifier: NCalculator.ICondition['id']) {
    return await this.asyncRequestHandler(
      { loader: 'getCalculatorConditionsItem' },
      this.$calculatorConditions.getItem(identifier)
    )
  }

  async createCalculatorConditionsItem () {
    return await this.asyncRequestHandler(
      { loader: 'createCalculatorConditionsItem' },
      this.$calculatorConditions.createItem()
    )
  }

  async editCalculatorConditionsItem (identifier: NCalculator.ICondition['id']) {
    return await this.asyncRequestHandler(
      { loader: 'editCalculatorConditionsItem' },
      this.$calculatorConditions.editItem(identifier)
    )
  }

  async removeCalculatorConditionsItem (identifier: NCalculator.ICondition['id']) {
    return await this.asyncRequestHandler(
      { loader: 'removeCalculatorConditionsItem' },
      this.$calculatorConditions.removeItem(identifier)
    )
  }

  conditionItemValidators: ValidatorParams<
    NCalculator.ICondition,
    | 'deadlineFrom'
    | 'deadlineUntil'
    | 'loanRate'
    | 'maxLoanAmount'
    | 'minLoanAmount'
    | 'name'
    > = {
      deadlineFrom: [{
        required: true,
        pattern: validatorsPattern.wholeNumbers,
        message: 'Введите число месяцев',
        trigger: ['blur'],
        type: 'number',
        min: 1
      }],
      deadlineUntil: [{
        required: true,
        pattern: validatorsPattern.wholeNumbers,
        message: 'Введите число месяцев',
        trigger: ['blur'],
        type: 'number',
        min: 1
      }],
      loanRate: [{
        required: true,
        pattern: validatorsPattern.wholeNumbers,
        message: 'Введите число процентов',
        trigger: ['blur'],
        type: 'number',
        min: 0
      }],
      maxLoanAmount: [{
        required: true,
        pattern: validatorsPattern.wholeNumbers,
        message: 'Введите число',
        trigger: ['blur'],
        type: 'number',
        min: 1
      }],
      minLoanAmount: [{
        required: true,
        pattern: validatorsPattern.wholeNumbers,
        message: 'Введите число',
        trigger: ['blur'],
        type: 'number',
        min: 1
      }],
      name: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название',
        trigger: ['blur']
      }]
    }
}
