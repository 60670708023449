import { Component } from 'nuxt-property-decorator'
import { ElementsPageParams } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import validatorsPattern from '~/utils/validators'
import { ValidatorParams } from '~/types/store/store-base-type'
import { NCalculator } from '~/types/store/calculator-micro'

@Component({
  name: 'CalculatorElementsMixin'
})
export default class CalculatorElementsMixin extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.statesReset('$calculatorElements')
    next()
  }

  created () {
    this.$wait.end('leaveRouter')
  }

  async getCalculatorElementsList (pageParams: ElementsPageParams<NCalculator.IElement>, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getCalculatorElementsList' },
      this.$calculatorElements.getList(
        pageParams,
        save)
    )
  }

  async getCalculatorELementsItem (identifier: NCalculator.IElement['id']) {
    return await this.asyncRequestHandler(
      { loader: 'getCalculatorElementsItem' },
      this.$calculatorElements.getItem(
        identifier)
    )
  }

  async createCalculatorElementsItem () {
    return await this.asyncRequestHandler(
      { loader: 'createCalculatorElementsItem' },
      this.$calculatorElements.createItem()
    )
  }

  async editCalculatorElementsItem (identifier: NCalculator.IElement['id']) {
    return await this.asyncRequestHandler(
      { loader: 'editCalculatorElementsItem' },
      this.$calculatorElements.editItem(
        identifier)
    )
  }

  async removeCalculatorElementsItem (identifier: NCalculator.IElement['id']) {
    return await this.asyncRequestHandler(
      { loader: 'removeCalculatorElementsItem' },
      this.$calculatorElements.removeItem(
        identifier)
    )
  }

  elementItemValidators: ValidatorParams<NCalculator.IElement, 'condition' | 'name'> = {
    condition: [{
      required: true,
      pattern: validatorsPattern.stringEmpty,
      message: 'Введите условие',
      trigger: ['blur']
    }],
    name: [{
      required: true,
      pattern: validatorsPattern.stringEmpty,
      message: 'Введите название',
      trigger: ['blur']
    }]
  }
}
