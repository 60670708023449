import { Module, Mutation } from 'vuex-module-decorators'
import { NCalculator } from '~/types/store/calculator-micro'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'calculatorTypes',
  stateFactory: true,
  namespaced: true
})
export default class CalculatorTypesModule extends BaseModule<
  NCalculator.ICalculatorType,
  'id',
  keyof NCalculator.ICalculatorType
> {
  protected config: ModuleConfig<NCalculator.ICalculatorType, 'id'> = {
    apiUrl: 'calculator/v1/types',
    identifier: 'id',
    apiUrlPrefixEnv: 'SITE_API'
  }

  protected itemData: NCalculator.ICalculatorType = {
    name: '',
    code: ''
  }

  @Mutation
  resetItem (): void {
    this.itemData = {
      name: '',
      code: ''
    }
  }
}
