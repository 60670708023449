import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import axios, { AxiosError } from 'axios'
import { FormError, PageParams, defaultData, ResponseData, ValidatorParams } from '../../interfaces'
import { GroupAttributes, Filters, AttributeValue } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'statusQuality',
  stateFactory: true,
  namespaced: true
})

export default class StatusQualityModule extends VuexModule {
  groupsAttributesValue: ResponseData<GroupAttributes> = defaultData

  filtersValue: Filters = {
    type: undefined
  }

  currentGroupValue: GroupAttributes = {
    active: false,
    code: '',
    createdAt: 0,
    id: 0,
    name: '',
    params: {},
    sort: 0,
    type: '',
    updatedAt: 0
  }

  currentAttributeValue: AttributeValue = {
    id: 0,
    groupId: 0,
    name: '',
    code: '',
    params: {},
    active: false,
    sort: 0,
    createdAt: 0,
    updatedAt: 0
  }

  attributesValue: ResponseData<AttributeValue> = {
    data: [],
    paginator: {
      pageSize: 0,
      currentPage: 0,
      totalItems: 0,
      totalPages: 0
    }
  }

  // ? ______________ getters ______________
  /**
   * * Получить значение тикета
   */
  get groupsAttributes (): ResponseData<GroupAttributes> {
    return this.groupsAttributesValue
  }

  get currentGroup (): GroupAttributes {
    return this.currentGroupValue
  }

  get currentAttribute (): AttributeValue {
    return this.currentAttributeValue
  }

  /**
   * * Получить фильтры групп статусов
   */
  get filters (): Filters {
    return this.filtersValue
  }

  get attributes (): ResponseData<AttributeValue> {
    return this.attributesValue
  }

  // ? ______________ setters ______________
  /**
   * * Установить значение тикета
   * @param filters - значение тикета
   */
  @Mutation
  setGroupsAttributesValue (val: ResponseData<GroupAttributes>) {
    this.groupsAttributesValue = val
  }

  @Mutation
  setAttributesValue (val: ResponseData<AttributeValue>) {
    this.attributesValue = val
  }

  @Mutation
  setCurrentAttribute (val: AttributeValue) {
    this.currentAttributeValue = val
  }

  @Mutation
  setCurrentGroup (group: GroupAttributes) {
    this.currentGroupValue = group
  }

    /**
   * * Установить значения фильтров
   * @param filters - значения фильтров
   */
    @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

    /**
   * * Сбросить значения фильтров
   */
    @Mutation
    resetFilters () {
      this.filtersValue = {
        type: undefined
      }
    }

    @Mutation
    resetCurrentGroup () {
      this.currentGroupValue = {
        active: false,
        code: '',
        createdAt: 0,
        id: 0,
        name: '',
        params: {},
        sort: 0,
        type: '',
        updatedAt: 0
      }
    }

    @Mutation
    resetCurrentAttribute () {
      this.currentAttributeValue = {
        id: 0,
        groupId: 0,
        name: '',
        code: '',
        params: {},
        active: false,
        sort: 0,
        createdAt: 0,
        updatedAt: 0
      }
    }

    // ? ______________________________________actions______________________________________
  /**
   * *
   * @param id заказа
   */
  @Action({
    rawError: true,
    commit: 'setGroupsAttributesValue'
  })
    async getGroupsAttributes ({
      siteApiUrl,
      pageParams
    }: {
    siteApiUrl: string;
    pageParams: PageParams | undefined
  }) {
      try {
        const { data } = await $axios.get(`${siteApiUrl}/attributes/v1/groups`, { params: pageParams, ...this.filters })
        const response: GroupAttributes[] = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

    @Action({
      rawError: true,
      commit: 'setAttributesValue'
    })
  async getAttribute ({
    siteApiUrl,
    id
  }: {
        siteApiUrl: string;
        id: string |(string | null)[]
      }) {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/attributes/v1/${id}`)
      const response: AttributeValue[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

    /**
   * *
   * @param id заказа
   */
    @Action({
      rawError: true
    })
    async createGroupsAttributes ({
      siteApiUrl,
      newGroupAttributes
    }: {
        siteApiUrl: string;
        newGroupAttributes: GroupAttributes
      }) {
      try {
        const { data } = await $axios.post(`${siteApiUrl}/attributes/v1/groups`, newGroupAttributes)
        const response: [GroupAttributes] = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

  @Action({
    rawError: true
  })
    async createAttribute ({
      siteApiUrl,
      attribute
    }: {
    siteApiUrl: string;
    attribute: AttributeValue;
  }) {
      try {
        const { data } = await $axios.post(`${siteApiUrl}/attributes/v1`, attribute)
        const response: AttributeValue[] = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

      /**
   * *
   * @param id заказа
   */
      @Action({
        rawError: true
      })
  async editGroupsAttributes ({
    siteApiUrl,
    id,
    groupAttributes
  }: {
          siteApiUrl: string;
          id: string | (string | null)[]
          groupAttributes: GroupAttributes
        }) {
    try {
      const { data } = await $axios.put(`${siteApiUrl}/attributes/v1/groups/${id}`, groupAttributes)
      const response: GroupAttributes[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
      async editAttribute ({
        siteApiUrl,
        id,
        attribute
      }: {
      siteApiUrl: string;
      id: number
      attribute: AttributeValue
    }) {
        try {
          const { data } = await $axios.put(`${siteApiUrl}/attributes/v1/${id}`, attribute)
          const response: AttributeValue[] = data
          return response
        } catch (error) {
          throw new FormError(error as AxiosError<FormError>)
        }
      }

          /**
   * *
   * @param id заказа
   */
          @Action({
            rawError: true
          })
  async deleteGroupsAttributes ({
    siteApiUrl,
    id
  }: {
              siteApiUrl: string;
              id: number
            }) {
    try {
      const { data } = await $axios.delete(`${siteApiUrl}/attributes/v1/groups/${id}`)
      const response: GroupAttributes[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
          async deleteAttribute ({
            siteApiUrl,
            id
          }: {
      siteApiUrl: string;
      id: number
    }) {
            try {
              const { data } = await $axios.delete(`${siteApiUrl}/attributes/v1/${id}`)
              const response: AttributeValue[] = data
              return response
            } catch (error) {
              throw new FormError(error as AxiosError<FormError>)
            }
          }
}
