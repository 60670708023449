import { Context } from '@nuxt/types'
import { OrdersPlugin, Orders, Order, Filters, ValidatorParams, GeOrderPriceRespData, ExcelType, EditOrderRequestData } from './interfaces'
import OrdersModule from './store'
import { PageParams } from '~/store/interfaces'

export default function getPluginApi (this: Context, ordersStore: OrdersModule) {
  const ctx = this
  const api: OrdersPlugin = {
    // ? ________ getters ________
    get ordersList (): Orders {
      return ordersStore.ordersList
    },
    orderById (id: number): Order | undefined {
      return ordersStore.orderById(id)
    },
    get validateData (): boolean {
      return ordersStore.validateData
    },
    get currentOrder (): Order {
      return ordersStore.currentOrder
    },
    get filters (): Filters {
      return ordersStore.filters
    },
    get validators (): ValidatorParams {
      return ordersStore.validators
    },

    // ? ________ setters ________
    set ordersList (orders: Orders) {
      ordersStore.setOrdersList(orders)
    },
    resetCurrentOrder () {
      ordersStore.resetCurrentOrder()
    },
    resetOrders () {
      ordersStore.resetOrders()
    },
    resetFilters () {
      ordersStore.resetFilters()
    },
    set filters (filters: Filters) {
      ordersStore.setFilters(filters)
    },
    set currentOrder (order: Order) {
      ordersStore.setCurrentOrder(order)
    },
    // ? ________ actions ________
    getOrders (pageParams: PageParams): Promise<Orders> {
      return ordersStore.getOrders(pageParams)
    },
    getOrder (id: number): Promise<Order> {
      return ordersStore.getOrder(id)
    },
    removeOrder (id: number): Promise<Order> {
      return ordersStore.removeOrder(id)
    },
    editOrder (id: number, data: EditOrderRequestData): Promise<void> {
      return ordersStore.editOrder({ siteApiUrl: ctx.$config.SITE_API, id, data })
    },
    createOrder (userId: number): Promise<{orderId: number}> {
      return ordersStore.createOrder({ userId, siteApiUrl: ctx.$config.SITE_API })
    },
    getOrderPrice (): Promise<GeOrderPriceRespData> {
      return ordersStore.getOrderPrice(ctx.$config.SITE_API)
    },
    applyDiscount (id: number, discount: number): Promise<any> {
      return ordersStore.applyDiscount({ discount, id, siteApiUrl: ctx.$config.SITE_API })
    },
    exportExcel (reqType: ExcelType = ExcelType.EXCEL, pageParams: PageParams | null = null): Promise<Blob> {
      return ordersStore.exportExcel(reqType, pageParams)
    }
  }
  return api
}
