import { Module, Mutation } from 'vuex-module-decorators'
import { TaskOption } from './interfaces'
import { ModuleConfig } from '~/types/store/store-base-type'
import BaseModule from '~/types/store/store-base'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'taskOptions'
})
export default class TaskOptionsModule extends BaseModule<TaskOption, 'id', keyof TaskOption> {
  protected config: ModuleConfig<TaskOption, 'id'> = {
    apiUrl: '/task/options',
    identifier: 'id'
  }

  protected itemData: TaskOption = {
    id: 0,
    name: '',
    params: {
      type: '',
      statuses: [],
      params: []
    },
    roles: [],
    createdAt: 0,
    updatedAt: 0
  }

    @Mutation
  resetItem () {
    this.itemData = {
      id: 0,
      name: '',
      params: {
        type: '',
        statuses: [],
        params: []
      },
      roles: [],
      createdAt: 0,
      updatedAt: 0
    }
  }
}
