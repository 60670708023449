import { TasksModule, TaskStatusesModule, TaskUserOptionsModule, TaskOptionsModule } from './store'
import TaskOptions from './store/task-options'
import TaskStatuses from './store/task-statuses'
import TaskUserOptions from './store/task-user-options'
import Tasks from './store/tasks'

export class TasksPlugin extends Tasks {
  options: TaskOptions
  statuses: TaskStatuses
  userOptions: TaskUserOptions
  constructor (tasksModule: TasksModule, taskStatusesModule: TaskStatusesModule, taskUserOptionsModule: TaskUserOptionsModule, taskOptionsModule: TaskOptionsModule) {
    super(tasksModule)
    this.options = new TaskOptions(taskOptionsModule)
    this.statuses = new TaskStatuses(taskStatusesModule)
    this.userOptions = new TaskUserOptions(taskUserOptionsModule)
  }
}

export default function getTasksApi (tasksModule: TasksModule, taskStatusesModule: TaskStatusesModule, taskUserOptionsModule: TaskUserOptionsModule, taskOptionsModule: TaskOptionsModule) {
  return new TasksPlugin(tasksModule, taskStatusesModule, taskUserOptionsModule, taskOptionsModule)
}
