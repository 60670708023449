import { Module, Mutation } from 'vuex-module-decorators'
import { PageTemplate, PageTemplateKeys } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'pageTemplates'
})
export default class extends BaseModule<PageTemplate, 'id', PageTemplateKeys, 'name' | 'code'> {
  protected listFilters: Partial<Pick<PageTemplate, 'name' | 'code'>> = {
    name: undefined,
    code: undefined
  }

  /**
   * Конфиг модуля
   */
  protected config: ModuleConfig<PageTemplate, 'id'> = {
    apiUrl: 'shop/page-templates',
    identifier: 'id'
  }

  /**
   * Стейт шаблона
   */
  protected itemData: PageTemplate = {
    code: '',
    fields: [],
    innerTemplateId: null,
    isList: false,
    name: '',
    params: {},
    previewId: null
  }

  /**
   * СБрос шаблона
   */
  @Mutation
  resetItem () {
    this.itemData = {
      code: '',
      fields: [],
      innerTemplateId: null,
      isList: false,
      name: '',
      params: {},
      previewId: null
    }
  }
}
