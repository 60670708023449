import { Component, mixins } from 'nuxt-property-decorator'
import StoreMixin from '~/mixins/store-mixin'

@Component({
  mixins: [StoreMixin]
})
export default class WidgetsMixin extends mixins(StoreMixin) {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$widgets.resetCurrentWidget()
    next()
  }

  /**
   * * Получение списка виджетов
   * @return список виджетов
   */
  async getWidgets () {
    try {
      this.$wait.start('getWidgets')
      return await this.$widgets.getWidgets()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getWidgets')
    }
  }

  /**
   * * Получение виджета по id
   * @param id - id виджета
   * @return виджет
   */
  async getWidget (id: number) {
    try {
      this.$wait.start('getWidget')
      return await this.$widgets.getWidget(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getWidget')
    }
  }

  /**
   * * Создание виджета
   * @return виджет
   */
  async createWidget () {
    try {
      this.$wait.start('createWidget')
      const widget = await this.$widgets.createWidget()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлен новый виджет ${widget[0].name}`
      })
      return widget
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createWidget')
    }
  }

  /**
   * * Изменение виджета
   * @return виджет
   */
  async editWidget () {
    try {
      this.$wait.start('editWidget')
      const widget = await this.$widgets.editWidget()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Изменен виджет ${widget.name}`
      })
      return widget
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editWidget')
    }
  }

  /**
   * * Удаление виджета
   * @param id - id виджета
   * @return виджет
   */
  async removeWidget (id: number) {
    try {
      this.$wait.start('editWidget')
      const widget = await this.$widgets.removeWidget(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Виджет ${widget.name} удален`
      })
      return widget
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editWidget')
    }
  }
}
