import { Action, Module, Mutation } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { ConditionsPageParams } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { FormError } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import { ModuleConfig, ResponseData } from '~/types/store/store-base-type'
import { NCalculator } from '~/types/store/calculator-micro'

@Module({
  name: 'calculatorConditions',
  stateFactory: true,
  namespaced: true
})
export default class CalculatorConditionsModule extends BaseModule<
  NCalculator.ICondition,
  'id',
  keyof NCalculator.ICondition
> {
  protected config: ModuleConfig<NCalculator.ICondition, 'id'> = {
    apiUrl: 'calculator/v1/conditions',
    identifier: 'id',
    apiUrlPrefixEnv: 'SITE_API'
  }

  protected itemData: NCalculator.ICondition = {
    deadlineFrom: null,
    deadlineUntil: null,
    fullLoanAmountFrom: 0,
    fullLoanAmountTo: 0,
    loanRate: 0,
    maxLoanAmount: 0,
    minLoanAmount: 0,
    name: ''
  }

  @Mutation
  resetItem (): void {
    this.itemData = {
      deadlineFrom: null,
      deadlineUntil: null,
      fullLoanAmountFrom: 0,
      fullLoanAmountTo: 0,
      loanRate: 0,
      maxLoanAmount: 0,
      minLoanAmount: 0,
      name: ''
    }
  }

  @Action({
    rawError: true
  })
  async getList (pageParams: ConditionsPageParams<NCalculator.ICondition>) {
    try {
      const { id, ...params } = pageParams
      const { data } = await $axios.get<ResponseData<NCalculator.ICondition>>(
        `/calculator/v1/elements/${id}/conditions`,
        {
          params: { ...params, ...this.listFilters },
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv
            }
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
