import { Action, Module, Mutation } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { NotificationTimeout, TaskUserOption, Type } from './interfaces'
import { ModuleConfig } from '~/types/store/store-base-type'
import BaseModule from '~/types/store/store-base'
import { $axios } from '~/utils/api'
import { FormError, ResponseData } from '~/store/interfaces'

const defaultData: TaskUserOption = {
  id: 0,
  userId: 0,
  name: '',
  code: '',
  params: {
    statuses: []
  },
  createdAt: 0,
  updatedAt: 0
}

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'taskUserOptions'
})
export default class TaskUserOptionsModule extends BaseModule<TaskUserOption, 'id', keyof TaskUserOption> {
  protected config: ModuleConfig<TaskUserOption, 'id'> = {
    apiUrl: '/task/user-option',
    identifier: 'id'
  }

  protected itemData: TaskUserOption = defaultData

    @Mutation
  resetItem () {
    this.itemData = defaultData
  }

    taskUserOptionByCode (code: string): TaskUserOption | undefined {
      return this.listData.data.find(option => option.code === code)
    }

    @Action({
      rawError: true
    })
    async getTimeouts () {
      try {
        const { data } = await $axios.get<ResponseData<NotificationTimeout>>(`${this.config.apiUrl}/notification-timeouts`)
        return data
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

    @Action({
      rawError: true
    })
    async getTypes () {
      try {
        const { data } = await $axios.get<ResponseData<Type>>(`${this.config.apiUrl}/types`)
        return data
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }
}
