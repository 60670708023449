import { Component, Vue } from 'nuxt-property-decorator'
import { RequestHandlerParams } from './interfaces'
import { BaseStoreModules } from '~/store'

/**
 * Миксин для компонентов с запросами. Содержит обработчик вызова метода запроса.
 */
@Component({
  name: 'PageTemplatesMixin'
})
export default class StoreMixin extends Vue {
  protected async asyncRequestHandler<T> ({ loader, message }: RequestHandlerParams<T>, cb: Promise<T>) {
    try {
      // Запуск лоадера
      this.$wait.start(loader)
      // Вызов метода запроса
      const response = await cb
      if (message) {
        this.$notify({
          type: 'success',
          title: 'Выполнено',
          message: message(response)
        })
      }
      return response
    } catch (e: any) {
      console.error(e)
      // Вывод ноитифая об ошибке
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      // Остановка лоадера
      this.$wait.end(loader)
    }
  }

  protected statesReset (pluginKey: keyof BaseStoreModules) {
    if (!pluginKey) { return }
    this[pluginKey]?.resetItem()
    this[pluginKey]?.resetList()
    this[pluginKey]?.resetFilters()
  }
}
