import { Component, Vue } from 'nuxt-property-decorator'
import { Manager } from './interfaces'

@Component
export default class ManagersMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  /**
   * * Запрос на получение списка групп статусов заказа
   * @param param - параметры пагинатора (необязательные)
   * @returns список групп статусов заказа
   */
  async getManagers (params?: {page?: number, pageSize?: number, sort?: string, order?: string}) {
    try {
      this.$wait.start('getManagers')
      return await this.$managers.getManagers({
        page: +(params?.page || 1),
        pageSize: params?.pageSize,
        sort: params?.sort,
        order: params?.order
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getManagers')
    }
  }

  async getManager (id: number) {
    try {
      this.$wait.start('getManager')
      return await this.$managers.getManager(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getManager')
    }
  }

  /**
   * * Запрос на создание группы
   * @returns группа статуса заказов
   */
  async createManager (manager: Manager) {
    try {
      this.$wait.start('createManager')
      const data = await this.$managers.createManager(manager)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Менеджер создан'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createManager')
    }
  }

  /**
   * * Запрос на создание группы
   * @returns группа статуса заказов
   */
  async editManager (id: number, manager: any) {
    try {
      this.$wait.start('editManager')
      const data = await this.$managers.editManager(id, manager)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Менеджер изменен'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editManager')
    }
  }

  /**
   * * Запрос на создание группы
   * @returns группа статуса заказов
   */
  async deleteManager (id: number) {
    try {
      this.$wait.start('deleteManager')
      const data = await this.$managers.deleteManager(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Менеджер удален'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('deleteManager')
    }
  }
}
