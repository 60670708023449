import {
  ConfigsPlugin,
  ConfigIds, ConfigItem
} from './interfaces'

export default function getPluginApi (configStore:any) {
  const ctx = this

  const api: ConfigsPlugin = {
    // ? ________ getters ________
    get configs (): ConfigItem[] {
      return configStore.configs
    },
    get config (): any {
      return configStore.config
    },
    get clientConfig (): any {
      return configStore.clientConfig
    },
    // ? ________ setters ________
    set configs (configs: ConfigItem[]) {
      configStore.setConfigs(configs)
    },
    set clientConfig (configs: any) {
      configStore.setClientConfig(configs)
    },
    resetConfigs () {
      configStore.resetConfigs()
    },
    set config (config: any) {
      configStore.setConfig(config)
    },
    resetConfig () {
      configStore.resetConfig()
    },
    // ? ________ actions ________
    getConfigs (): Promise<ConfigItem[]> {
      return configStore.getConfigs()
    },
    getClientConfigs (...keys: any[]): Promise<any> {
      return configStore.getClientConfigs({ siteApiUrl: ctx.$config.SITE_API, configKey: ctx.$config.CONFIG_KEY, keys })
    },
    addClientConfig (key: string, newConfig: any = configStore.config): Promise<any> {
      return configStore.addClientConfig({ siteApiUrl: ctx.$config.SITE_API, configKey: ctx.$config.CONFIG_KEY, key, newConfig })
    },
    editClientConfig (key: string, newConfig: any = configStore.config): Promise<any> {
      return configStore.editClientConfig({ siteApiUrl: ctx.$config.SITE_API, configKey: ctx.$config.CONFIG_KEY, key, newConfig })
    },

    async getConfig (id: ConfigIds, save = true): Promise<any> {
      const config = await configStore.getConfigById(id)
      save && configStore.setConfig(config)
      return config
    },
    editConfigs (config: ConfigItem[]): Promise<ConfigItem[]> {
      return configStore.editConfigs(config)
    },
    editConfig (): Promise<any> {
      return configStore.editConfig()
    },
    dropCache (): Promise<any> {
      return configStore.dropCache()
    }
  }
  return api
}
