import { Module, Mutation } from 'vuex-module-decorators'
import { Task } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'
import TaskStatuses from '~/store/modules/tasks/store/task-statuses/store'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'tasks'
})
export default class TasksModule extends BaseModule<Task, 'id', keyof Task> {
  protected config: ModuleConfig<Task, 'id'> = {
    apiUrl: '/task',
    identifier: 'id'
  }

  statuses = TaskStatuses

  protected itemData: Task = {
    id: 0,
    name: '',
    description: '',
    managerId: 0,
    executorId: null,
    statusId: 1,
    priority: 2,
    link: '',
    linkText: '',
    startDate: Date.now() / 1000,
    endDate: +new Date(Date.now() + (24 * 60 * 60 * 1000)) / 1000,
    createdAt: 0,
    updatedAt: 0,
    checklist: [],
    params: {}
  }

    @Mutation
  resetItem () {
    this.itemData = {
      id: 0,
      name: '',
      description: '',
      managerId: 0,
      executorId: null,
      statusId: 1,
      priority: 2,
      link: '',
      linkText: '',
      startDate: Date.now() / 1000,
      endDate: +new Date(Date.now() + (24 * 60 * 60 * 1000)) / 1000,
      createdAt: 0,
      updatedAt: 0,
      checklist: [],
      params: {}
    }
  }
}
