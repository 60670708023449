import { Component } from 'nuxt-property-decorator'
import StoreMixin from '~/mixins/store-mixin'
import validatorsPattern from '~/utils/validators'
import type { PageParams, ValidatorParams } from '~/types/store/store-base-type'
import { NCalculator } from '~/types/store/calculator-micro'

@Component({
  name: 'CalculatorsMixin'
})
export default class CalculatorsMixin extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.statesReset('$calculators')
    next()
  }

  created () {
    this.$wait.end('leaveRouter')
  }

  /**
   * * Запрос на список калькуляторов
   * @param pageParams параметры пагинации
   * @param save статус сохранения значения в стор
   * @returns список шаблонов
   */
  async getCalculatorsList (pageParams?: PageParams<NCalculator.ICalculator>, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getCalculatorsList' },
      this.$calculators.getList(
        pageParams,
        save)
    )
  }

  /**
   * Запрос на калькулятор по идентификатору
   * @param identifier идентификатор шаблона
   * @param save статус сохранения значения в стор
   * @returns калькулятор
   */
  async getCalculatorsItem (identifier: NCalculator.ICalculator['id'], save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getCalculatorsItem' },
      this.$calculators.getItem(
        identifier,
        save)
    )
  }

  /**
   * Запрос на создание калькулятора
   * @returns калькулятор
   */
  async createCalculatorsItem () {
    return await this.asyncRequestHandler(
      { loader: 'createCalculatorsItem' },
      this.$calculators.createItem()
    )
  }

  /**
   * Запрос на изменение калькулятора
   * @param identifier идентификатор калькулятора
   * @returns калькулятор
   */
  async editCalculatorsItem (identifier: NCalculator.ICalculator['id']) {
    return await this.asyncRequestHandler(
      { loader: 'editCalculatorsItem' },
      this.$calculators.editItem(
        identifier)
    )
  }

  /**
   * Запрос на удаление калькулятора
   * @param identifier идентификатор калькулятора
   * @returns калькулятор
   */
  async removeCalculatorsItem (identifier: NCalculator.ICalculator['id']) {
    return await this.asyncRequestHandler(
      { loader: 'removeCalculatorsItem' },
      this.$calculators.removeItem(
        identifier)
    )
  }

  /**
   * Валидаторы калькулятора
   */
  calculatorItemValidators: ValidatorParams<NCalculator.ICalculator, 'code' | 'typeId' | 'name'> = {
    code: [{
      required: true,
      pattern: validatorsPattern.stringEmpty,
      message: 'Введите код',
      trigger: ['blur']
    }],
    typeId: [{
      required: true,
      message: 'Выберите тип калькулятора',
      trigger: ['blur']
    }],
    name: [{
      required: true,
      pattern: validatorsPattern.stringEmpty,
      message: 'Введите название',
      trigger: ['blur']
    }]
  }
}
