export enum ConfigIds {
  'manager-emails' = 'manager-emails',
  'personnel-list' = 'personnel-list',
  'css-options' = 'css-options',
  'price-limits' = 'price-limits',
  'roles-options' = 'roles-options',
  'watermark-options' = 'watermark-options',
  'images-options' = 'images-options',
  'paychase-discount' = 'paychase-discount',
  'related-products-options' = 'related-products-options',
  'similar-products' = 'similar-products',
  'seo-options' = 'seo-options',
  'admin-menu-buttons' = 'admin-menu-buttons',
  'delivery' = 'delivery',
  'pages-options' = 'pages-options',
}

export interface ConfigItem {
  id: number
  name: string
  value: any
  schema: object
}

/**
 * * Объект плагина
 */
export interface ConfigsPlugin {
  /**
   * * Шаблоны configs
   */
  configs: ConfigItem[]
  // Очистка стора конфига
  resetConfigs: () => void
  /**
   * * Объект конфига
   */
  config: any
  // Очистка стора конфига
  resetConfig: () => void
  /**
   * * Запрос на список configs
   */
  getConfigs: () => Promise<ConfigItem[]>
  /**
   * * Запрос на config по id
   */
  getConfig: (id: ConfigIds, save?: boolean) => Promise<any>
  /**
   * * Запрос на изменение config
   */
  editConfigs: (configParams: ConfigItem[]) => Promise<ConfigItem[]>
  /**
   * * Запрос на изменение config по id
   */
  editConfig: () => Promise<any>
  dropCache: () => Promise<any>

  clientConfig: any

  getClientConfigs: (...keys: any[]) => Promise<any>

  addClientConfig: (key: string, newConfig: any) => Promise<any>

  editClientConfig: (key: string, newConfig: any) => Promise<any>
}
