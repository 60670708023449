import { Component, Vue } from 'nuxt-property-decorator'
import { GroupAttributes, AttributeValue } from './interfaces'

@Component
export default class StatusQualityMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  /**
   * * Запрос на получение списка групп статусов заказа
   * @param param - параметры пагинатора (необязательные)
   * @returns список групп статусов заказа
   */
  async getGroupsAttributes (params?: {page?: number, pageSize?: number, sort?: string, order?: string}) {
    try {
      this.$wait.start('getStatusGroups')
      return await this.$statusQuality.getGroupsAttributes({
        page: +(params?.page || 1),
        pageSize: params?.pageSize,
        sort: params?.sort,
        order: params?.order
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getStatusGroups')
    }
  }

  /**
   * * Запрос на создание группы
   * @returns группа статуса заказов
   */
  async createStatusGroup (newGroupAttributes: GroupAttributes) {
    try {
      this.$wait.start('createStatusGroup')
      const data = await this.$statusQuality.createGroupsAttributes(newGroupAttributes)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Группа создана'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createStatusGroup')
    }
  }

  /**
   * * Запрос на создание группы
   * @returns группа статуса заказов
   */
  async editGroupsAttributes (id: string | (string | null)[], groupAttributes: GroupAttributes) {
    try {
      this.$wait.start('createStatusGroup')
      const data = await this.$statusQuality.editGroupsAttributes(id, groupAttributes)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Группа изменена'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createStatusGroup')
    }
  }

  /**
   * * Запрос на создание группы
   * @returns группа статуса заказов
   */
  async deleteGroupsAttributes (id: number) {
    try {
      this.$wait.start('createStatusGroup')
      const data = await this.$statusQuality.deleteGroupsAttributes(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Группа удалена'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createStatusGroup')
    }
  }

  async editAttributeMixin (id: number, attribute: AttributeValue) {
    try {
      this.$wait.start('editAttribute')
      const data = await this.$statusQuality.editAttribute(id, attribute)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Атрибут изменен'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editAttribute')
    }
  }

  async createAttribute (attribute: AttributeValue) {
    try {
      this.$wait.start('createStatusGroup')
      const data = await this.$statusQuality.createAttribute(attribute)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Атрибут создан'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createStatusGroup')
    }
  }

  async deleteAttributeMixin (id: number) {
    try {
      this.$wait.start('createStatusGroup')
      const data = await this.$statusQuality.deleteAttribute(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Атрибут удален'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createStatusGroup')
    }
  }
}
