import { Context } from '@nuxt/types'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { GroupAttributes, StatusQualityPlugin, Filters, AttributeValue } from './interfaces'

import StatusQualityModule from './store'

export default function getPluginApi (store: StatusQualityModule) {
  const ctx: Context = this
  const api: StatusQualityPlugin = {
    get filters (): Filters {
      return store.filters
    },

    get groupsAttributes () {
      return store.groupsAttributes
    },

    get currentGroup () {
      return store.currentGroup
    },

    get currentAttribute () {
      return store.currentAttribute
    },

    get attributes () {
      return store.attributes
    },
    // ? ________ setters ________
    set filters (filters: Filters) {
      store.setFilters(filters)
    },
    set currentGroup (group: GroupAttributes) {
      store.setCurrentGroup(group)
    },
    set currentAttribute (attributes: AttributeValue) {
      store.setCurrentAttribute(attributes)
    },
    set attributes (attribute: ResponseData<AttributeValue>) {
      store.setAttributesValue(attribute)
    },
    resetCurrentGroup () {
      store.resetCurrentGroup()
    },
    resetCurrentAttribute () {
      store.resetCurrentAttribute()
    },
    resetFilters () {
      store.resetFilters()
    },

    set groupsAttributes (val: ResponseData<GroupAttributes>) {
      store.setGroupsAttributesValue(val)
    },

    // ? ________ actions ________
    getGroupsAttributes (pageParams?: PageParams): Promise<GroupAttributes[]> {
      return store.getGroupsAttributes({ siteApiUrl: ctx.$config.SITE_API, pageParams })
    },

    getAttribute (id: string | (string | null)[]): Promise<AttributeValue[]> {
      return store.getAttribute({ siteApiUrl: ctx.$config.SITE_API, id })
    },

    createGroupsAttributes (newGroupAttributes: GroupAttributes): Promise<GroupAttributes[]> {
      return store.createGroupsAttributes({ siteApiUrl: ctx.$config.SITE_API, newGroupAttributes })
    },

    createAttribute (attribute: AttributeValue): Promise<AttributeValue[]> {
      return store.createAttribute({ siteApiUrl: ctx.$config.SITE_API, attribute })
    },

    editGroupsAttributes (id: string | (string | null)[], groupAttributes: GroupAttributes): Promise<GroupAttributes[]> {
      return store.editGroupsAttributes({ siteApiUrl: ctx.$config.SITE_API, id, groupAttributes })
    },

    editAttribute (id: number, attribute: AttributeValue): Promise<AttributeValue[]> {
      return store.editAttribute({ siteApiUrl: ctx.$config.SITE_API, id, attribute })
    },

    deleteGroupsAttributes (id: number): Promise<GroupAttributes[]> {
      return store.deleteGroupsAttributes({ siteApiUrl: ctx.$config.SITE_API, id })
    },

    deleteAttribute (id: number): Promise<AttributeValue[]> {
      return store.deleteAttribute({ siteApiUrl: ctx.$config.SITE_API, id })
    }
  }
  return api
}
