import { Component } from 'nuxt-property-decorator'
import StoreMixin from '~/mixins/store-mixin'

@Component
export default class FormsParamsMixin extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$formParams.resetFormParam()
    this.$formParams.resetFormParamType()
    next()
  }

  // ? ___________Form Params___________
  /**
   * * Получение списка параметров формы
   * @param typeId - id формы
   * @returns список параметров формы
   */
  async getFormParams (typeId?: number, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getFormParams' },
      this.$formParams.getFormParams({ typeId },
        save)
    )
  }

  /**
   * * Добавление параметра
   * @returns параметр
   */
  async createFormParam () {
    return await this.asyncRequestHandler(
      { loader: 'createFormParam' },
      this.$formParams.createFormParam()
    )
  }

  /**
   * * Удаление параметра
   * @param ids - id парметров [1]
   * @returns параметр
   */
  async removeFormParam (ids: number[]) {
    return await this.asyncRequestHandler(
      { loader: 'removeFormParam' },
      this.$formParams.removeFormParam(ids[0])
    )
  }

  // ? ___________Form Param Types___________
  /**
   * * Сделать запрос на получение типов параметров
   * @param type - тип модуля (review, form)
   * @returns список типов параметров
   */
  async getFormParamTypes (type: 'form' | 'review' | undefined = undefined) {
    return await this.asyncRequestHandler(
      { loader: 'getFormParamTypes' },
      this.$formParams.getFormParamTypes({ type })

    )
  }

  /**
   * * Сделать запрос изменение типа параметра
   * @returns тип параметра
   */
  async editFormParamType () {
    return await this.asyncRequestHandler(
      { loader: 'editFormParamType' },
      this.$formParams.editFormParamType()
    )
  }

  /**
   * * Сделать запрос на создание типа параметра
   * @returns тип параметра
   */
  async createFormParamType () {
    return await this.asyncRequestHandler(
      { loader: 'createFormParamType' },
      this.$formParams.createFormParamType()
    )
  }

  /**
   * * Сделать запрос на удаление типа параметра
   * @param id - id типа параметрв
   * @returns тип параметра
   */
  async removeFormParamType (id: number) {
    return await this.asyncRequestHandler(
      { loader: 'removeFormParamType' },
      this.$formParams.removeFormParamType(id)
    )
  }
}
