import { Module, Mutation } from 'vuex-module-decorators'
import { TaskStatus } from './interfaces'
import { ModuleConfig } from '~/types/store/store-base-type'
import BaseModule from '~/types/store/store-base'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'taskStatuses'
})
export default class TaskStatusesModule extends BaseModule<TaskStatus, 'id', keyof TaskStatus> {
  protected config: ModuleConfig<TaskStatus, 'id'> = {
    apiUrl: '/task/status',
    identifier: 'id'
  }

  protected itemData: TaskStatus = {
    id: 0,
    name: '',
    code: '',
    sort: 0,
    params: []
  }

    @Mutation
  resetItem () {
    this.itemData = {
      id: 0,
      name: '',
      code: '',
      sort: 0,
      params: []
    }
  }
}
