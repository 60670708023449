import type { TaskUserOption } from './interfaces'
import TaskUserOptionsModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class TaskUserOptions extends BasePlugin<TaskUserOption, 'id', keyof TaskUserOption | any, TaskUserOptionsModule> {
  taskUserOptionByCode (code: string): TaskUserOption | undefined {
    return this.module.taskUserOptionByCode(code)
  }

  getTypes () {
    return this.module.getTypes()
  }

  getTimeouts () {
    return this.module.getTimeouts()
  }

  resetItem () {
    this.module.resetItem()
  }
}
