import { Vue, Component } from 'nuxt-property-decorator'

@Component
export default class DefaultDeliveryRegionsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$defaultDeliveryRegion.resetDefaultDeliveryRegion()
    next()
  }

  async getDefaultDeliveryRegions (params: any) {
    try {
      this.$wait.start('getDefaultDeliveryRegions')
      return await this.$defaultDeliveryRegion.getDefaultDeliveryRegions(params)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getDefaultDeliveryRegions')
    }
  }

  async createDefaultDeliveryRegion (id: number) {
    try {
      this.$wait.start('createDefaultDeliveryRegion')
      const data = await this.$defaultDeliveryRegion.createDefaultDeliveryRegion(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Добавлен новый регион'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createDefaultDeliveryRegion')
    }
  }

  async editDefaultDeliveryRegion () {
    try {
      this.$wait.start('editDefaultDeliveryRegion')
      const data = await this.$defaultDeliveryRegion.editDefaultDeliveryRegion()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Регион изменён'
      })
      // message :todo
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editDefaultDeliveryRegion')
    }
  }

  async removeDefaultDeliveryRegion (id: number) {
    try {
      this.$wait.start('removeDefaultDeliveryRegion')
      const data = await this.$defaultDeliveryRegion.removeDefaultDeliveryRegion(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Регион удалён'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeDefaultDeliveryRegion')
    }
  }
}
