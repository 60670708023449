import { Page, PageKeys } from './interfaces'
import PagesModule from './store'
import { PageParams } from '~/types/store/store-base-type'
import BasePlugin from '~/types/store/plugin-base'

export class Pages extends BasePlugin<Page, 'id', PageKeys, PagesModule, 'title'> {
  removeItemPropByKeys = (...keys: PageKeys[]) => {
    this.module.removeItemPropByKeys(keys)
  }

  updateChildren = ({ api, id }: { api: 'sites' | 'roles'; id: number }): Promise<void> => {
    return this.module.updateChildren({ api, id })
  }

  async getList (pageParams?: PageParams<Page>, save: boolean = true, useFilters = false) {
    const data = await this.module.getList({ ...pageParams, useFilters })
    if (save) {
      this.module.setList(data)
    }
    return data
  }
}

export default function getPluginApi (store: PagesModule) {
  return new Pages(store)
}
