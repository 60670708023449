import { Context } from '@nuxt/types'
import { FormatPlugin } from '~/store/modules/format/interfaces'
import { wordRussianEnding } from '~/utils/string'

export default function getPluginApi () {
  const ctx: Context = this
  const api: FormatPlugin = {
    site (prop: any) {
      return prop.siteId ? ctx.app.$sites.siteById(prop.siteId)?.name || `${prop.siteId}` : ''
    },
    sites (prop: any) {
      const siteNames: any = []
      prop.siteId.forEach((item: any) => {
        siteNames.push({ name: ctx.app.$sites.siteById(item)?.name })
      })
      return siteNames
    },

    priceRole (prop: any) {
      return prop.role ? ctx.app.$priceRoles.priceRoleByName(prop.role)?.title || `${prop.role}` : ''
    },

    formParams (prop: any) {
      const formParamTypeId = ctx.app.$formParams.formParamById(prop.id)?.formParamTypeId
      if (!formParamTypeId) { return '' }
      return ctx.app.$formParams.formParamTypeById(formParamTypeId)?.name || `${formParamTypeId}`
    },

    emailTemplates (prop: any) {
      return prop.templateId ? ctx.app.$emailTemplates.templateById(prop.templateId)?.name || `${prop.templateId}` : ''
    },

    diffDate (date1: number, date2: number): string {
      // Вычисляем разницу в миллисекундах
      const difference = Math.abs(date1 - date2)

      // Создаем новый объект Date, содержащий разницу
      const diffDate = new Date(difference)
      // Извлекаем годы, месяцы, дни, часы, минуты и секунды из объекта Date
      const years = Math.floor(diffDate.getUTCFullYear() - 1970) // Потому что устанавливаем 1970 год как начальный
      const months = diffDate.getUTCMonth()
      const days = diffDate.getUTCDate() - 1 // -1, потому что getUTCDate возвращает значение от 1 до 31
      const hours = diffDate.getUTCHours()
      const minutes = diffDate.getUTCMinutes()

      // Формируем строку
      let result = ''
      if (years > 0) {
        result += `${years} ${wordRussianEnding(years, ['год', 'года', 'лет'])}, `
      }
      if (months > 0) {
        result += `${months} ${wordRussianEnding(months, ['месяц', 'месяца', 'месяцев'])}, `
      }
      if (days > 0) {
        result += `${days} ${wordRussianEnding(days, ['день', 'дня', 'дней'])}, `
      }
      if (hours > 0) {
        result += `${hours} ${wordRussianEnding(hours, ['час', 'часа', 'часов'])}, `
      }
      if (minutes > 0) {
        result += `${minutes} ${wordRussianEnding(minutes, ['минута', 'минуты', 'минут'])}, `
      }

      // Удаляем последнюю запятую и пробел
      result = result.slice(0, -2)

      return result
      // const days = diffDate.getUTCDate() - 1 // -1, потому что getUTCDate возвращает значение от 1 до 31
      // const hours = diffDate.getUTCHours()
      // const minutes = diffDate.getUTCMinutes()
      // return `${days} ${wordRussianEnding(days, ['день', 'дня', 'дней'])}, ${hours} ${wordRussianEnding(hours, ['час', 'часа', 'часов'])}, ${minutes} ${wordRussianEnding(minutes, ['минута', 'минуты', 'минут'])}`
    },

    createdAtDate (prop: any) {
      if (prop.createdAt === null || prop.createdAt === undefined) { return '' }
      const date = new Date(prop.createdAt * 1000)
      return new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(date)
    },

    offerChangeDate (prop: any) {
      if (prop === null) { return '' }
      const date = new Date(prop * 1000)
      return new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(date)
    },

    updatedAtDate (prop: any) {
      if (prop.updatedAt === null || prop.updatedAt === undefined) { return '' }
      const date = new Date(prop.updatedAt * 1000)
      return new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(date)
    },

    startedAtDate (prop: any) {
      if (!prop?.startedAt) { return '' }
      const date = new Date(prop.startedAt * 1000)
      return new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(date)
    },

    //* получить дату для столбца даты завершения
    endedAtDate (prop: { endDate: number | undefined }) {
      if (!prop.endDate || prop.endDate === undefined) { return '-' }
      const date = new Date(prop.endDate * 1000)
      return new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(date)
    },

    price (prop: any, params?: { round?: 'up' | 'down', nosign?: boolean }) {
      let price
      switch (params?.round) {
        case 'up':
          price = Math.ceil(prop.price)
          break
        case 'down':
          price = Math.floor(prop.price)
          break
        default:
          price = prop.price
          break
      }
      return new Intl.NumberFormat('ru-RU', params?.nosign ? {} : { style: 'currency', currency: ctx.app.$currencies.currencyById(prop?.currencyId)?.sign || 'RUB', minimumFractionDigits: 0 }).format(price)
    },

    priceOld (prop: any, params?: { round?: 'up' | 'down', nosign?: boolean }) {
      let price
      switch (params?.round) {
        case 'up':
          price = Math.ceil(prop)
          break
        case 'down':
          price = Math.floor(prop)
          break
        default:
          price = prop
          break
      }
      return new Intl.NumberFormat('ru-RU', params?.nosign ? {} : { style: 'currency', currency: ctx.app.$currencies.currencyById(prop.currencyId)?.sign || 'RUB', minimumFractionDigits: 0 }).format(price)
    },

    date (prop: number) {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
      return Intl.DateTimeFormat('ru-RU', options).format(new Date((prop ?? 0)).valueOf())
    },

    role (prop: string) {
      return ctx.app.$roles.roleByName(prop)?.description ?? prop
    },

    currency (prop: any) {
      if (!prop?.currencyId) { return '' }
      return ctx.app.$currencies.currencyById(prop.currencyId)?.name ?? prop.currencyId
    }
  }
  return api
}
