import { Component } from 'nuxt-property-decorator'
import { TaskOption } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams } from '~/types/store/store-base-type'

@Component({
  name: 'TaskOptionsMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    // Запуск лоадера перехода по страницам
    this.$wait.start('leaveRouter')
    this.$tasks.options.resetItem()
    next()
  }

  getTaskOptions (pageParams?: PageParams<TaskOption>, save = true) {
    return this.asyncRequestHandler({
      loader: 'getTaskOptions'
    },
    this.$tasks.options.getList(pageParams,
      save)
    )
  }

  getTaskOption (id: TaskOption['id'], save = true) {
    return this.asyncRequestHandler(
      { loader: 'getTaskOption' },
      this.$tasks.options.getItem(id,
        save)
    )
  }

  createTaskOption () {
    return this.asyncRequestHandler(
      {
        loader: 'createTaskOption',
        message: (task: TaskOption) => `Опция ${task.name} создана`
      },
      this.$tasks.options.createItem()
    )
  }

  editTaskOption (id: TaskOption['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'editTaskOption',
        message: (task: TaskOption) => `Опция ${task.name} изменена`
      },
      this.$tasks.options.editItem(id)

    )
  }

  removeTaskOption (id: TaskOption['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'removeTaskOption',
        message: (task: TaskOption) => `Опция ${task.name} удалена`
      },
      this.$tasks.options.removeItem(id)

    )
  }
}
