import { Component } from 'nuxt-property-decorator'
import StoreMixin from '~/mixins/store-mixin'

@Component
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$eventTriggers.resetItem()
    this.$eventTriggers.resetList()
    next()
  }

  created () {
    this.$wait.end('leaveRouter')
  }

  /**
   * Обработчик запроса на получение списка триггеров
   * @param save параметр сохранения данных в стор
   * @returns список триггеров
   */
  async getEventTriggersList (save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getEventTriggersList' },
      this.$eventTriggers.getList(save)
    )
  }

  /**
   * Обработчик запроса на обновление promo-sender`а
   */
  async updatePromoSender () {
    await this.asyncRequestHandler(
      { loader: 'updatePromoSender' },
      this.$eventTriggers.updatePromoSender()
    )
  }
}
