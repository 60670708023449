import { DefaultDeliveryRegionsPlugin, DefaultDeliveryRegion } from './interfaces'
import DefaultDeliveryRegionsModule from './store'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: DefaultDeliveryRegionsModule) {
  const api: DefaultDeliveryRegionsPlugin = {
    // ? ________ getters ________

    get defaultDeliveryRegion (): DefaultDeliveryRegion {
      return store.defaultDeliveryRegion
    },

    get defaultDeliveryRegions (): ResponseData<DefaultDeliveryRegion> {
      return store.defaultDeliveryRegions
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    defaultDeliveryRegionById (id: number): DefaultDeliveryRegion | undefined {
      return store.defaultDeliveryRegionById(id)
    },

    // ? ________ setters ________

    set defaultDeliveryRegion (defaultDeliveryRegion: DefaultDeliveryRegion) {
      store.setDefaultDeliveryRegion(defaultDeliveryRegion)
    },

    set defaultDeliveryRegions (defaultDeliveryRegions: ResponseData<DefaultDeliveryRegion>) {
      store.setDefaultDeliveryRegions(defaultDeliveryRegions)
    },

    resetDefaultDeliveryRegion () {
      store.resetDefaultDeliveryRegion()
    },

    // ? ________ actions ________
    getDefaultDeliveryRegions (params: any): Promise<ResponseData<DefaultDeliveryRegion>> {
      return store.getDefaultDeliveryRegions(params)
    },

    createDefaultDeliveryRegion (serviceId: number) {
      return store.createDefaultDeliveryRegion(serviceId)
    },

    editDefaultDeliveryRegion () {
      return store.editDefaultDeliveryRegion()
    },

    removeDefaultDeliveryRegion (id: number) {
      return store.removeDefaultDeliveryRegion(id)
    }

  }

  return api
}
