import { Component } from 'nuxt-property-decorator'
import { TaskUserOption } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams } from '~/types/store/store-base-type'

@Component({
  name: 'TaskUserOptionMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    // Запуск лоадера перехода по страницам
    this.$wait.start('leaveRouter')
    this.$tasks.userOptions.resetItem()
    next()
  }

  getTaskUserOptions (pageParams?: PageParams<TaskUserOption>, save = true) {
    return this.asyncRequestHandler({
      loader: 'getTaskUserOptions'
    },
    this.$tasks.userOptions.getList(pageParams,
      save)

    )
  }

  getTaskUserOption (id: TaskUserOption['id'], save = true) {
    return this.asyncRequestHandler(
      { loader: 'getTaskUserOption' },
      this.$tasks.userOptions.getItem(id,
        save)

    )
  }

  getTaskNotificationTimeouts () {
    return this.asyncRequestHandler(
      { loader: 'getTaskNotificationTimeouts' },
      this.$tasks.userOptions.getTimeouts()
    )
  }

  createTaskUserOption (item?: Omit<TaskUserOption, 'id' | 'createdAt' | 'updatedAt'>) {
    return this.asyncRequestHandler(
      {
        loader: 'createTaskUserOption'
      },
      this.$tasks.userOptions.createItem(item)
    )
  }

  editTaskUserOption (id: TaskUserOption['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'editTaskUserOption'
      },
      this.$tasks.userOptions.editItem(id)
    )
  }

  removeTaskUserOption (id: TaskUserOption['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'removeTaskUserOption',
        message: (task: TaskUserOption) => `Опция ${task.name} удалена`
      },
      this.$tasks.userOptions.removeItem(id)
    )
  }
}
